import barba from "@barba/core";
import gsap from "gsap";
import htmx from "htmx.org";

const BARBA_STATUS_AFTER = "after";

if (history.scrollRestoration) {
  history.scrollRestoration = "manual";
}

document.addEventListener("DOMContentLoaded", function () {
  const transitionEl = document.getElementById("page-transition-element");

  // @NOTE
  // For more information about configuration / setup and possibilities, see https://barba.js.org/docs/getstarted/intro/
  barba.init({
    debug: false,
    logLevel: "error",
    prefetchIgnore: true,
    preventRunning: true,
    timeout: 15000,
    prevent: ({ el }) => el.classList && el.classList.contains("barba-prevent"),
    requestError: (trigger, action, url, response) => {
      // go to a custom 404 page if the user click on a link that return a 404 response status
      // if (action === 'click' && response.status && response.status === 404) {
      //     return true;
      // }

      // @NOTE
      // Prevent Barba from redirecting the user to the requested URL
      // This is equivalent to e.preventDefault() in this context
      return false;
    },
    transitions: [
      {
        name: "simple",
        // @NOTE
        // The "to" key is to set a rule to know when this animation should run or not based on the next page namespace.
        to: {
          namespace: ["simple"],
        },
        leave(data) {
          window.dispatchEvent(
            new CustomEvent("Overlay.close", {
              detail: {
                force: true,
              },
            })
          );
          if (transitionEl) transitionEl.style = "transform: translateY(0);";
          gsap.to(document.querySelector("footer"), {
            opacity: 0,
            duration: 0.1,
          });
          return gsap.to(data.current.container, {
            opacity: 0,
            duration: 0.25,
            delay: 0.1,
            onComplete: function () {
              data.current.container.classList.add("hide");
            },
          });
        },
        enter(data) {
          return gsap.from(data.next.container, {
            opacity: 0,
            duration: 0.3,
          });
        },
      },
      {
        name: "complex",
        to: {
          namespace: ["complex"],
        },
        leave(data) {
          window.dispatchEvent(
            new CustomEvent("Overlay.close", {
              detail: {
                force: true,
              },
            })
          );
          gsap.set(data.current.container, { zIndex: 2 });
          gsap.to(document.querySelector("header"), {
            yPercent: -100,
            duration: 0.5,
          });
          gsap.to(document.querySelector("footer"), {
            opacity: 0,
            duration: 0.1,
          });
          gsap.to(data.current.container, {
            y: -90,
            ease: "power3.in",
            duration: 0.75,
          });
          return gsap.fromTo(
            transitionEl,
            {
              yPercent: 0,
            },
            {
              yPercent: -100,
              duration: 0.7,
              delay: 0.05,
              ease: "power1.in",
            }
          );
        },
        enter(data) {
          data.next.container.style = "z-index: 3; position: fixed; top: 0;";
          gsap.fromTo(
            data.next.container.querySelector("header"),
            { yPercent: -100 },
            {
              yPercent: 0,
              duration: 0.5,
              delay: 0.8,
            }
          );

          // Animations for the homepage hero, only after a transition (we don't have animations on first load)
          const homepageHero =
            data.next.container.querySelector(".homepage.hero");
          if (homepageHero) {
            gsap.fromTo(
              homepageHero.querySelector(".main-image"),
              { width: 0 },
              {
                width: "100%",
                duration: 1,
                delay: 1.2,
                ease: "power2.inOut",
              }
            );
            gsap.fromTo(
              homepageHero.querySelector("h1"),
              { opacity: 0 },
              {
                opacity: 1,
                duration: 0.01,
                delay: 1.4,
              }
            );
            gsap.fromTo(
              homepageHero.querySelectorAll(".primary-cta"),
              { clipPath: "inset(0px 0px 100% 0px)", top: 50 },
              {
                clipPath: "inset(0 0 0% 0)",
                top: 0,
                duration: 0.75,
                delay: 2.3,
                ease: "power1.Out",
              }
            );
            // setTimeout car on a un splitText dans hero-homepage.js qui découpe le h1 en plusieurs div
            setTimeout(() => {
              if (window.scrollY == 0) {
                gsap.fromTo(
                  homepageHero.querySelectorAll("h1 div"),
                  { clipPath: "inset(0px 0px 100% 0px)", top: 50 },
                  {
                    clipPath: "inset(0 0 0% 0)",
                    top: 0,
                    duration: 0.75,
                    delay: 1,
                    ease: "power1.Out",
                  }
                );
              }
            }, 1000);
          }
          return gsap.fromTo(
            data.next.container,
            {
              y: "100vh",
            },
            {
              y: 0,
              duration: 0.7,
              ease: "power1.in",

              onStart: () => {
                const sections = data.next.container.querySelectorAll(
                  ":scope > section:not(#overlay-mobile-menu), .filters-grid-layout"
                );
                const sprig =
                  data.next.container.querySelector(".sprig-component");

                sections.forEach((section) => {
                  section.style.visibility = "hidden";
                  gsap.set(section, { y: 50 });
                });
                if (sprig !== null) {
                  sprig.style.opacity = 0;
                }
              },
              onComplete() {
                data.next.container.style = "z-index: 3;";
                gsap.set(transitionEl, { yPercent: 0 });

                const sections = data.next.container.querySelectorAll(
                  ":scope > section:not(#overlay-mobile-menu), .filters-grid-layout"
                );
                const sprig =
                  data.next.container.querySelector(".sprig-component");

                // Animate each section's appearance
                gsap.fromTo(
                  sections,
                  { opacity: 0, y: 50 },
                  {
                    opacity: 1,
                    y: 0,
                    duration: 0.6,
                    ease: "power1.in",
                    // stagger: 0.25,
                    onStart: () => {
                      sections.forEach((section) => {
                        section.style.visibility = "visible";
                      });
                    },
                    onComplete: () => {
                      if (sprig !== null) {
                        sprig.style.opacity = 1;
                      }
                    },
                  }
                );
              },
            }
          );
        },
      },
    ],
  });

  //for dev purpose only, prevent yii admin bar to make redirect call with barba js.
  //timeout is needed because the debug toolbar is added after our script here.
  setTimeout(function () {
    document
      .querySelectorAll("#yii-debug-toolbar a")
      .forEach((item) => item.setAttribute("data-barba-prevent", "self"));
  }, 1500);

  barba.hooks.before((data) => {
    document.querySelector("html").classList.add("in-transition");
  });

  barba.hooks.afterLeave((data) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });

    // @NOTE
    // Get DOM of next and current page to compare the list of script found in the page to add or to remove them.
    const currentDOM = new DOMParser().parseFromString(
      data.current.html,
      "text/html"
    );
    const nextDOM = new DOMParser().parseFromString(
      data.next.html,
      "text/html"
    );
    const currentArrayScript = Array.from(
      currentDOM.querySelectorAll("body script")
    );
    const nextArrayScript = Array.from(nextDOM.querySelectorAll("body script"));

    document.documentElement.lang = nextDOM.documentElement.lang;

    //replace footer content (in case we go from english to french)
    if (
      document.querySelector(".footer-wrapper") &&
      nextDOM.querySelector(".footer-wrapper")
    ) {
      document.querySelector(".footer-wrapper").innerHTML =
        nextDOM.querySelector(".footer-wrapper").innerHTML;
    }
    gsap.to(document.querySelector("footer"), { opacity: 0, duration: 0 });

    //@NOTE
    // Parse all scripts from the next page to detect if they doesn't exit in the current page to add them
    let scriptNodeToAdd = [];
    let scriptNodeToRemove = [];
    let scriptRemoved = 0;
    let scriptLoaded = 0;

    nextArrayScript.forEach((nScript, nIndex) => {
      if (nScript.attributes && nScript.attributes.src) {
        let nScriptDom = document.querySelector(
          `script[src="${nScript.attributes.src.value}"]`
        );

        if (!nScriptDom) {
          // Append script
          let scriptNode = document.createElement("script");
          scriptNode.async = nScript.attributes.async;
          scriptNode.src = nScript.attributes.src.value;
          scriptNodeToAdd.push(scriptNode);
        }
      }
    });

    //@NOTE Parse all scripts from the current page to detect if they doesn't exit in the current page to remove them
    currentArrayScript.forEach((cScript, nIndex) => {
      if (cScript.attributes && cScript.attributes.src) {
        let cSprintDom = nextDOM.querySelector(
          `script[src="${cScript.attributes.src.value}"]`
        );

        if (!cSprintDom) {
          // Delete script
          scriptNodeToRemove.push(
            document.querySelector(
              `script[src="${cScript.attributes.src.value}"]`
            )
          );
        }
      }
    });

    // @NOTE
    // Create two promise for add and remove to be sure all scripts will be loaded or removed and then we can go to the next state of barba
    let addScripts = new Promise((resolve, reject) => {
      if (scriptNodeToAdd.length > 0) {
        scriptNodeToAdd.forEach((node, index) => {
          document.querySelector("body").appendChild(node);
          node.addEventListener("load", () => {
            scriptLoaded++;

            if (scriptLoaded === scriptNodeToAdd.length) {
              resolve();
            }
          });
        });
      } else {
        resolve();
      }
    });

    let removeScripts = new Promise((resolve, reject) => {
      if (scriptNodeToRemove.length > 0) {
        scriptNodeToRemove.forEach((node, index) => {
          //some instance seemed to remove the script from the body within the content and tried afterward to remove the script from the body
          if (document.querySelector("body").hasChildNodes(node)) {
            document.querySelector("body").removeChild(node);
          }
          scriptRemoved++;
          if (scriptRemoved === scriptNodeToRemove.length) {
            resolve();
          }
        });
      } else {
        resolve();
      }
    });

    let changeClass = new Promise((resolve, reject) => {
      let body = document.querySelector("body");
      let nextBody = nextDOM.querySelector("body");

      //nextDOM.querySelector('html').classList.remove('in-transition');
      body.classList.remove(body.getAttribute("data-background-color"));
      body.classList.add(nextBody.getAttribute("data-background-color"));

      document
        .querySelector("html")
        .setAttribute(
          "class",
          nextDOM.querySelector("html").getAttribute("class")
        );

      body.setAttribute(
        "data-background-color",
        nextBody.getAttribute("data-background-color")
      );
      resolve();
    });

    // Return Promise since Barba works with promises. Won't go to the next state until all promises are done.
    return Promise.all([addScripts, removeScripts, changeClass]);
  });

  barba.hooks.beforeEnter((data) => {
    htmx.process(data.next.container);
  });

  barba.hooks.afterEnter((data) => {
    // @TODO: Enable this snippet when using AddToAny share buttons
    // @NOTE: Must remove the script from the twig template where buttons are.
    // Check if the new page has a2a share buttons
    const shareContainer = data.next.container.querySelector(".a2a_kit");

    if (shareContainer) {
      // If AddToAny is already loaded, just re-init
      if (window.a2a) {
        window.a2a.init("page");
      } else {
        // Dynamically load the AddToAny script if not loaded
        const script = document.createElement("script");
        script.src = "https://static.addtoany.com/menu/page.js";
        script.async = true;
        script.onload = () => {
          if (window.a2a) {
            window.a2a.init("page");
          }
        };
        script.onerror = () => {
          console.warn("AddToAny script failed to load");
        };
        document.body.appendChild(script);
      }
    }
  });

  barba.hooks.after((data) => {
    // @NOTE
    // Dispatch new page is now the current page
    // We init again all manage me views at the same time to restart and clear all events

    window.dispatchEvent(
      new CustomEvent("pageTransition", {
        detail: {
          status: BARBA_STATUS_AFTER,
        },
      })
    );

    //autoplay videos with such attribute on after load. Taken from: https://github.com/barbajs/barba/issues/596
    var vids = document.querySelectorAll("video[autoplay]");
    vids.forEach((vid) => {
      var playPromise = vid.play();
      if (playPromise !== undefined) {
        playPromise.then((_) => {}).catch((error) => {});
      }
    });

    setTimeout(function () {
      Me.skin.initFields();
      Me.manage.initViews();
      window.dispatchEvent(new Event("resize"));
      gsap.to(document.querySelector("footer"), { opacity: 1, delay: 0.5 });
    }, 200);

    document.querySelector("html").classList.remove("no-scroll");

    //make sure to go to anchors
    if (location.hash.slice(1)) {
      document.getElementById(location.hash.slice(1)).scrollIntoView();
    }
  });
});
